.row {
    margin-bottom: 1%;
}
h1 {
    font-size: large;
}

span {
    text-decoration: underline;
}

.wcag{
    max-width: 300px;
    padding: 0;
}

.wars{
    max-width: 200px;
    padding: 0;
}


.conformance{
    font-size: 50px;
}